<template>
  <el-dialog v-if="dialogVisible" :visible.sync="dialogVisible" @close="closeDialog()" class="dialog-900" append-to-body width="100%">
    <el-row :gutter="20" class="dialog-body" style="margin-left: 0; margin-right: 0">
      <h2 v-if="productObj.value === 7" class="font20 boldFont big_title">
        NO LIMIT! Countdown 2024 PARTY PASS
      </h2>
      <h2 v-else-if="productObj.value === 9" class="font20 boldFont big_title">
        1 Day Studio Pass + Have Fun in Kansai
      </h2>
      <h2 v-else class="font20 boldFont big_title">
        {{ getTicketTypeName(productObj.value) }} Day Studio Pass
      </h2>
      <el-col :span="24" :md="8" class="didalog-body-left">
        <div v-if="productObj.value !==7">
            <p v-if="productObj.value === 1" class="padding_b_16 font14" style="word-break: normal; color: #333333">
          Select the start date of your
          {{ getTicketTypeName(productObj.value) }}-day ticket. The price shown
          on the day you select is the price of your
          {{ getTicketTypeName(productObj.value) }}-day ticket.
        </p>
        <p v-else class="padding_b_16 font14" style="word-break: normal; color: #333333">
          Select the start date of your
          {{ getTicketTypeName(productObj.value) }}-days ticket. The price shown
          on the day you select is the price of your
          {{ getTicketTypeName(productObj.value) }}-days ticket.
        </p>
        </div>
        <div v-else>
          <p class="padding_b_16 font14" style="word-break: normal; color: #333333">
       This "hyper exciting" event always sells out! The one-night-only extravaganza is back again this year!
 <br><br>
       Event Period:19:00 on Dec. 31, 2023 - 21:00 on Jan. 1, 2024
        </p>
        </div>
        <el-link type="primary" class="font14" style="color: #036ce2" @click="handleDetail">View Details</el-link>
      </el-col>
      <el-col :span="24" :md="16" class="dialog-body-right" v-loading="loading" style="padding-right: 0">
        <div v-if="newifTodayLater" class="gwcts">Please clear your cart if you wish to purchase tickets for other dates.</div>
        <p class="currentDate">
          <!-- <i @click="prevMonth()" class="el-icon-arrow-left mr15 calendar_arrow" :style="{visibility: date.getMonth() <= new Date().getMonth() && date.getFullYear() <= new Date().getFullYear() ? 'hidden' : ''}" /> -->
          <img :src="leftIconPath" @click="prevMonth()" style="vertical-align: initial" class="mr15 calendar_arrow" :style="{
              visibility:
                date.getMonth() <= new Date().getMonth() &&
                date.getFullYear() <= new Date().getFullYear()
                  ? 'hidden'
                  : '',
            }" />
          <span class="calendar-head mr15 darkBlueFont">{{
            dateFilter(date)
          }}</span>
          <img :src="rightIconPath" @click="nextMonth()" class="calendar_arrow" :style="{ visibility: beforeEndMonth ? '' : 'hidden' }" style="vertical-align: inherit" />
          <!-- <i @click="nextMonth()" class="el-icon-arrow-right calendar_arrow" :style="{visibility: beforeEndMonth()? '' : 'hidden' }" style="vertical-align: inherit;" /> -->
        </p>
        <!-- 日历票列表 1-->
        <el-calendar v-model="date" :first-day-of-week="7" :class="{
            'preMonth-disclick': date.getMonth() <= new Date().getMonth(),
           
          }">
          <template slot="dateCell" slot-scope="{ data }">
            <div v-if="isShowBefore" class="dateCell" ref="calendarCell" :class="{
                datecell_disclick:
                  beforeToday(data.day) ||
                  InventoryShortage(data.day) ||
                  notOnSelectableDate(data.day),
                datecell_click:
                  !beforeToday(data.day) &&
                  !InventoryShortage(data.day) &&
                  !notOnSelectableDate(data.day),
                click_active: travelDate === data.day,
              }" @click="selectDate(data)">
              <p style="text-align: right">{{ data.day.split("-")["2"] }}</p>
              <p v-if="betweenDay(data.day) && notOnSelectableDate(data.day)">
                Disabled
              </p>
              <p v-else-if="
                  !notOnSelectableDate(data.day) &&
                  InventoryShortage(data.day) &&
                  betweenDay(data.day)
                ">
                <span v-if="productObj.value !== 7" style="color:#ec1018;font-weight:bold">{{ifNoStock(data.day)?'No stock':'Available at Park'}}</span>
              </p>
              <div v-else>
                <div v-if="getProInfo(data.day, 'adultPro') &&getProInfo(data.day, 'adultPro').salePrice &&productObj.adultVotes > 0" class="mt10">
                  <p>
                    ¥{{
                      getProInfo(data.day, "adultPro").salePrice | formataPrice
                    }}
                  </p>
                  <div class="color-bar" :style="{
                      backgroundColor: getProInfo(data.day, 'adultPro')
                        .ticketColor,
                    }"></div>
                </div>
                <div v-else-if="
                    getProInfo(data.day, 'childPro') &&
                    getProInfo(data.day, 'childPro').salePrice
                  ">
                  <p>
                    ¥{{
                      getProInfo(data.day, "childPro").salePrice | formataPrice
                    }}
                  </p>
                  <div class="color-bar" :style="{
                      backgroundColor: getProInfo(data.day, 'childPro')
                        .ticketColor,
                    }"></div>
                </div>
                <!-- 老人 -->
                <div v-else-if="
                    getProInfo(data.day, 'seniorPro') &&
                    getProInfo(data.day, 'seniorPro').salePrice
                  ">
                  <p>
                    ¥{{
                      getProInfo(data.day, "seniorPro").salePrice | formataPrice
                    }}
                  </p>
                  <div class="color-bar" :style="{
                      backgroundColor: getProInfo(data.day, 'seniorPro')
                        .ticketColor,
                    }"></div>
                </div>
                <!-- 全年 -->
                <div v-else-if="
                    getProInfo(data.day, 'allYearPro') &&
                    getProInfo(data.day, 'allYearPro').salePrice
                  ">
                  <p>
                    ¥{{
                      getProInfo(data.day, "allYearPro").salePrice | formataPrice
                    }}
                  </p>
                  <div class="color-bar" :style="{
                      backgroundColor: getProInfo(data.day, 'allYearPro')
                        .ticketColor,
                    }"></div>
                </div>
              </div>
            </div>
            <div v-else>
              <p style="text-align: right">{{ data.day.split("-")["2"] }}</p>
              <p v-if="betweenDay(data.day) && notOnSelectableDate(data.day)">
                Disabled
              </p>
              <p v-else-if="
                  !notOnSelectableDate(data.day) &&
                  InventoryShortage(data.day) &&
                  betweenDay(data.day)
                ">
                <span v-if="productObj.value !== 7" style="color:#ec1018;font-weight:bold">{{ifNoStock(data.day)?'No stock':'Available at Park'}}</span>
              </p>
              <div v-else>
                <div v-if="
                    getProInfo(data.day, 'adultPro') &&
                    getProInfo(data.day, 'adultPro').salePrice &&
                    productObj.adultVotes > 0
                  " class="mt10">
                  <p>
                    ¥{{
                      getProInfo(data.day, "adultPro").salePrice | formataPrice
                    }}
                  </p>
                  <div class="color-bar" :style="{
                      backgroundColor: getProInfo(data.day, 'adultPro')
                        .ticketColor,
                    }"></div>
                </div>
                <div v-else-if="
                    getProInfo(data.day, 'childPro') &&
                    getProInfo(data.day, 'childPro').salePrice
                  ">
                  <p>
                    ¥{{
                      getProInfo(data.day, "childPro").salePrice | formataPrice
                    }}
                  </p>
                  <div class="color-bar" :style="{
                      backgroundColor: getProInfo(data.day, 'childPro')
                        .ticketColor,
                    }"></div>
                </div>
              </div>
            </div>
          </template>
        </el-calendar>
        <!-- 价格行 -->
        <el-row style="margin-bottom: 24px">
          <!-- 颜色分类 -->
          <el-col class="priceLevelBox" :span="24" :md="16">
            <div v-if="priceType.length > 0" class="priceColorBox" style="display: flex">
              <div v-for="(item, index) of priceType" :key="index" class="price-level-item">
                <div class="cube" :style="{ backgroundColor: item.color }"></div>
                <p class="price">¥{{ item.salePrice | abs }}</p>
              </div>
            </div>
            <!-- 占位div，防止没有价格的时候总价往前挪 -->
            <div v-else style="height: 1px"></div>
            <!-- 有效期 -->
            <div v-if="travelDate" class="select_product_info">
              <!-- <div><img src="@/assets/calendar.png" alt=""></div> -->
              <!-- <span>{{ conversionPeriod() }}</span> -->
            </div>
          </el-col>
          <el-col :span="24" :md="8" class="priceDetail">
            <p v-show="productObj.value===7">
              {{ productObj.allYearVotes }}
              All Ages
              <span>¥{{allYearProduct.salePrice * productObj.allYearVotes || 0 | abs}}</span>
            </p>
            <p v-show="productObj.value!==7">
              {{ productObj.adultVotes }}
              <span v-text="productObj.adultVotes < 2 ? 'Adult:' : 'Adults:'"></span>
              <span>¥{{adultProduct.salePrice * productObj.adultVotes || 0 | abs}}</span>
            </p>
            <p v-show="productObj.value!==7 && productObj.value!==9">
              {{ productObj.childVotes }}
              <span v-text="productObj.childVotes < 2 ? 'Child:' : 'Children:'"></span>
              <span>¥{{childProduct.salePrice * productObj.childVotes || 0 | abs}}</span>
            </p>
            <p v-show="productObj.name==='一日票'">
              {{ productObj.seniorVotes }}
              <span v-text="productObj.seniorVotes < 2 ? 'Senior:' : 'Seniors:'"></span>
              <span>¥{{seniorProduct.salePrice * productObj.seniorVotes || 0 | abs}}</span>
            </p>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!-- <div>相差天数{{differDay}},开始{{new Date(`2021-11-18`)}},开始年{{date.getFullYear()}},开始月{{date.getMonth()}},开始时间戳{{date.getTime()}},结束{{endTime}},结束时间戳{{new Date(endTime).getTime()}},月份判断{{beforeEndMonth}}</div>
    <br>
    <div>开始demo:{{demoBegin}},结束demo:{{demo1End}},开始demo时间戳{{demoBegin.getTime()}},结束demo时间戳{{demo1End.getTime()}}</div> -->
    <el-row class="dialog-foot">
      <el-col>
        <p class="subtotal">
          <span>Subtotal:</span>
          <span class="font_weight_700 font20" style="color: #0a0a0a; line-height: 1.5">¥{{ subtotal() || 0 | abs }}</span>
        </p>
        <div class="btn_box">
          <el-button class="cancel_btn" @click="closeDialog()" type="primary" plain>CANCEL</el-button>
          <el-button @click="addToCart()" type="success" :disabled="btnDisabled" class="addCartBtn" style="margin-left: 0">ADD TO CART</el-button>
        </div>
      </el-col>
    </el-row>
    <close-icon v-if="dialogVisible" @closeDialog="closeDialog()" />
  </el-dialog>
</template>

<script>
import { getCalendarProductList, ifNoStock } from "@/api/index";
import { formatDate, getDateStr, objListConcat, seniorListConcat, judgeMonthFrist, judgeIphone, returnDateResults, getDaysBetweenDie } from "@/utils/util";
import { mixin2, ifTodayLater } from "@/mixin/index";
import closeIcon from "@/components/close_icon.vue";
import axios from "axios";
import { dateTimeFormatByZone } from "@/utils/date.js";
import { mapState, mapMutations } from "vuex";
var demoBegin = null;
var demo1End = null;
export default {
  mixins: [mixin2],
  components: { closeIcon },
  computed: {
    ...mapState([
      "oneDayTicketAvailableDate",
      "twoDayAvailableDate",
      "oneFiveTicketAvailableDate",
      "maxSaleObj"
    ]),
    // 判断当前月份是否在当天开始的60天之内
    beforeEndMonth() {
      let res;
      if (this.endTime) {
        let endYear = this.endTime.split("-")[0];
        // let endMonth = parseInt(this.endTime.split('-')[1]) - 1 < 10 ? `0${parseInt(this.endTime.split('-')[1]) - 1}` : parseInt(this.endTime.split('-')[1]) - 1
        // endMonth === '00' ? endMonth = '01' : ''
        let endMonth = Number(this.endTime.split("-")[1]);
        endMonth < 10 ? `0${endMonth}` : endMonth;
        console.log(endMonth);
        let endDay = this.endTime.split("-")[2];
        let endDate = new Date(`${endYear}-${endMonth}-${endDay}`);
        let curYear = this.date.getFullYear();
        let curMonth =
          this.date.getMonth() + 1 < 10
            ? `0${this.date.getMonth() + 1}`
            : this.date.getMonth() + 1;
        let beginDate = new Date(`${curYear}-${curMonth}-${endDay}`);
        demoBegin = beginDate;
        demo1End = endDate;
        console.log(parseInt(curMonth),parseInt(endMonth));
        if (endDay !== '31') {
          if (beginDate < new Date(this.endTime)) {
            res = true;
          } else {
            res = false;
          }
        } else {
          endMonth = endMonth < 10 ? `0${endMonth}` : endMonth;
          res = false
          console.log("判断的", parseInt(curYear.toString()+curMonth.toString()));
          console.log("设置的",parseInt(endYear.toString()+endMonth.toString()));
          parseInt(curYear.toString()+curMonth.toString()) < parseInt(endYear.toString()+endMonth.toString()) ? res = true : res = false
        }
      }
      return res;
    }
  },
  data() {
    return {
      dialogVisible: true,
      date: new Date(),
      ccc: null,
      travelDate: "", // 游玩日期
      productObj: {}, // 所选择的票，包括票数在内
      priceCalendar: [], // 总的日历价格
      adultPriceCalendar: [], // 成人日历价格
      childPriceCalendar: [], // 儿童日历价格
      seniorPriceCalendar: [], // 老人日历价格
      allYearPriceCalendar:[], //全年票日曆價格
      adultProduct: 0, // 成人产品
      childProduct: 0, // 儿童产品
      seniorProduct: 0, // 老人产品
      allYearProduct:0, //全年票日曆產品
      btnDisabled: true,
      loading: false,
      priceType: [], // 价格种类
      timeZoneList: [], // 时区列表
      onTimeZone: 1, // 时区值
      zone: "",
      endTime: "",
      isShowBefore: true,
      differDay: 0,
      demoBegin: null,
      demo1End: null,
      leftIconPath: require("../../assets/left.png"),
      rightIconPath: require("../../assets/right_arrow.png"),
      mobile_flag: false, // true为PC端，false为手机端
      isIphone: false,
      isMac: null,
      ifNoStockList: [],
      newifTodayLater: false,
    };
  },
  watch: {
    // date() {
    //   this.getCalendarProductList()
    // },
  },
  methods: {
    ...mapMutations(["SET_SHOPCART", "SET_TICKET_DATE"]),
    async init(item) {
      this.newifTodayLater = ifTodayLater();
      this.isIphone = judgeIphone()
      this.isMac = /macintosh|mac os x/i.test(navigator.userAgent);
      this.differDay = this.maxSaleObj.oneTicketDeadLine === undefined ? returnDateResults(this.date) : returnDateResults(this.date, new Date(this.maxSaleObj.oneTicketDeadLine))
      // this.differDay = getDaysBetweenDie(this.date, '2022-08-31')
      // this.differDay = this.date.toString().indexOf('GMT-') === -1 ? getDaysBetweenDie(this.date, '2022-08-31') : getDaysBetweenDie(this.date, '2022-09-01')
      this.SET_SHOPCART();
      this.SET_TICKET_DATE();
      this.date = new Date();
      await this.getTimezones(); // 获取时区
      this.adultProduct = {};
      this.childProduct = {};
      this.seniorProduct = {};
      this.btnDisabled = true;
      this.priceCalendar = [];
      this.loading = true;
      this.dialogVisible = true;
      this.priceType = [];
      this.productObj = item; // 所选择的票，包括票数在内
      this.judgeTicket(this.productObj.name)
      await this.getCalendarProductList();
      this.getPriceType();
      this.travelDate = "";
      this.getSalePrice();
      // judgeMonthFrist(this.date.getMonth(), 0)

      await this.getIfNoStock();
    },
    // 获取是否显示 No stock
    async getIfNoStock(){
      let res = await ifNoStock();
      this.ifNoStockList = JSON.parse(res.data.data[0].content)
      // console.log('getIfNoStock',this.ifNoStockList);
    },
    // 判断不同票数量
    judgeTicket(ticketTitle) {
      if (JSON.parse(localStorage.getItem("orderInfo")) !== null) {
        let orderInfoArr = JSON.parse(localStorage.getItem("orderInfo")).orderRequestProductList
        let adultitemNumTotal = 0
        let childitemNumTotal = 0
        let seniortemNumTotal = 0
        let allYearNumTotal = 0
        orderInfoArr.forEach(item => {
          // 判断是什么票种进来的
          if (this.productObj.name === ticketTitle) {
            item.ticketName === ticketTitle && item.ticketType === 1 ? adultitemNumTotal += item.num : '' //目前缓存中成人票数量
            item.ticketName === ticketTitle && item.ticketType === 2 ? childitemNumTotal += item.num : '' //目前缓存中儿童票数量
            item.ticketName === ticketTitle && item.ticketKind === 7 ? allYearNumTotal += item.num : '' //目前缓存中全年龄票数量
            if (item.ticketName === '一日票') item.ticketName === ticketTitle && item.ticketType === 3 ? seniortemNumTotal += item.num : '' //目前缓存中老人票数量
            if (this.productObj.adultVotes !== 0 && this.productObj.childVotes !== 0 && this.productObj.seniorVotes !== 0) {
              // 如果成人和儿童和老人都有数量 如果成人或儿童或老人其中一种票加起来超过4 就不能加入购物车
              this.productObj.adultVotes + adultitemNumTotal > 4 || this.productObj.childVotes + childitemNumTotal > 4 || this.productObj.seniorVotes + seniortemNumTotal > 4 ? this.btnDisabled = true : this.btnDisabled = false
            } else if (this.productObj.adultVotes !== 0 && this.productObj.childVotes !== 0) {
              // 如果成人和儿童都有数量 如果成人或儿童其中一种票加起来超过4 就不能加入购物车
              this.productObj.adultVotes + adultitemNumTotal > 4 || this.productObj.childVotes + childitemNumTotal > 4 ? this.btnDisabled = true : this.btnDisabled = false
            } else if (this.productObj.adultVotes !== 0 && this.productObj.seniorVotes !== 0) {
              // 如果成人和老人都有数量 如果成人或老人其中一种票加起来超过4 就不能加入购物车
              this.productObj.adultVotes + adultitemNumTotal > 4 || this.productObj.seniorVotes + seniortemNumTotal > 4 ? this.btnDisabled = true : this.btnDisabled = false
            } else if (this.productObj.childVotes !== 0 && this.productObj.seniorVotes !== 0) {
              // 如果儿童和老人都有数量 如果儿童或老人其中一种票加起来超过4 就不能加入购物车
              this.productObj.childVotes + childitemNumTotal > 4 || this.productObj.seniorVotes + seniortemNumTotal > 4 ? this.btnDisabled = true : this.btnDisabled = false
            } else {
              // 单选情况
              if (item.ticketName === ticketTitle && item.ticketType === 1) {
                // 成人
                if (this.productObj.adultVotes !== 0) {
                  this.productObj.adultVotes + adultitemNumTotal > 4 ? this.btnDisabled = true : this.btnDisabled = false
                }
              }
              else if (item.ticketName === ticketTitle && item.ticketType === 2) {
                // 儿童
                if (this.productObj.childVotes !== 0) {
                  this.productObj.childVotes + childitemNumTotal > 4 ? this.btnDisabled = true : this.btnDisabled = false
                }
              } else if (item.ticketName === ticketTitle && item.ticketType === 3) {
                // 老人
                if (this.productObj.seniorVotes !== 0) {
                  this.productObj.seniorVotes + seniortemNumTotal > 4 ? this.btnDisabled = true : this.btnDisabled = false
                }
              }
              else if (item.ticketName === ticketTitle && item.ticketKind === 7) { 
                // 全年
                if (this.productObj.allYearVotes !== 0) {
                  this.productObj.allYearVotes + allYearNumTotal > 4 ? this.btnDisabled = true : this.btnDisabled = false
                }
              }
            }
          }
        })
        console.log(this.subtotal());
      }
    },

    // 获取日历票列表
    async getCalendarProductList() {
      setTimeout(() => {
        this.demoBegin = demoBegin;
        this.demo1End = demo1End;
      }, 200);
      this.loading = true;
      const date = this.date;
      const month =
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1; // 当前月份
      const startTime = this.getNowFormatDate();
      this.endTime = getDateStr(startTime, this.differDay);

      // 获取成人产品
      const params1 = {
        startTime,
        endTime: this.endTime,
        ticketKind: this.productObj.value,
        ticketType: this.productObj.adultTicket
      };
      // 获取儿童产品
      const params2 = {
        startTime,
        endTime: this.endTime,
        ticketKind: this.productObj.value,
        ticketType: this.productObj.childTicket
      };
      // 获取老人产品
      const params3 = {
        startTime,
        endTime: this.endTime,
        ticketKind: this.productObj.value,
        ticketType: this.productObj.seniorTicket
      };
       // 获取全年产品
      const params4 = {
        startTime,
        endTime: this.endTime,
        ticketKind: this.productObj.value,
       
      };
      if(this.productObj.value!==7){
      this.adultPriceCalendar = await this.getCalendarData(params1);
      this.childPriceCalendar = await this.getCalendarData(params2);
      this.seniorPriceCalendar = await this.getCalendarData(params3);
      }else{
      this.allYearPriceCalendar = await this.getCalendarData(params4);
      }
      this.mergePriceCalendar();
    },
    // 合并日历价格
    mergePriceCalendar() {
      this.priceCalendar = [];
      // 如果有成人和儿童和老人
      if (
        this.productObj.adultVotes > 0 &&
        this.productObj.childVotes > 0 &&
        this.productObj.seniorVotes > 0
      ) {
        let arr = []
        arr = objListConcat(
          "travelTime",
          this.adultPriceCalendar,
          this.childPriceCalendar
        );
        this.priceCalendar = seniorListConcat(
          "travelTime",
          arr,
          this.seniorPriceCalendar
        );

      }
      else {
        // 否则直接赋给日历价格
        // 成人
        if (this.productObj.adultVotes > 0) {
          this.adultPriceCalendar.forEach(item => {
            var option = {
              adultProduct: item,
              travelTime: item.travelTime
            };
            this.priceCalendar.push(option);
          });
        }
        // 儿童
        else if (this.productObj.childVotes > 0) {
          this.childPriceCalendar.forEach(item => {
            var option = {
              childProduct: item,
              travelTime: item.travelTime
            };
            this.priceCalendar.push(option);

          });
        }
        // 老人
        else if (this.productObj.seniorVotes > 0) {
          this.seniorPriceCalendar.forEach(item => {
            // this.seniorProduct = item
            var option = {
              seniorProduct: item,
              travelTime: item.travelTime
            };
            this.priceCalendar.push(option);
          });
        }
        // 全年票
        else if (this.productObj.allYearVotes > 0) {
          this.allYearPriceCalendar.forEach(item => {
            // this.seniorProduct = item
            var option = {
              allYearProduct: item,
              travelTime: item.travelTime
            };
            this.priceCalendar.push(option);
          });
        }
      }
    },
    //获取成人价格种类
    getPriceType() {
      this.priceType = [];
      let currentMon = this.date.getMonth() + 1;
      // currentMon === 12 ? currentMon = '1' : ''
      currentMon < 10 ? (currentMon = "0" + currentMon) : "";
      // 如果选了成人票就获取成人价格种类
      if (this.productObj.adultVotes > 0) {
        if (this.adultPriceCalendar.length > 0) {
          this.adultPriceCalendar.map(proItem => {
            if (currentMon.toString() === proItem.travelTime.split("-")[1]) {
              if (!this.includeObj(this.priceType, proItem)) {
                this.priceType.push({
                  salePrice: proItem.salePrice,
                  color: proItem.ticketColor,
                  productId: proItem.productDto.id
                });
              }
            }
          });
        }
      } else if (this.productObj.childVotes > 0) {

        if (this.childPriceCalendar.length > 0) {
          this.childPriceCalendar.map(proItem => {
            if (currentMon.toString() === proItem.travelTime.split("-")[1]) {
              if (!this.includeObj(this.priceType, proItem)) {
                this.priceType.push({
                  salePrice: proItem.salePrice,
                  color: proItem.ticketColor,
                  productId: proItem.productDto.id
                });
              }
            }
          });
        }
      }
      else if (this.productObj.seniorVotes > 0) {
        if (this.seniorPriceCalendar.length > 0) {
          this.seniorPriceCalendar.map(proItem => {
            if (currentMon.toString() === proItem.travelTime.split("-")[1]) {
              if (!this.includeObj(this.priceType, proItem)) {
                this.priceType.push({
                  salePrice: proItem.salePrice,
                  color: proItem.ticketColor,
                  productId: proItem.productDto.id
                });
              }
            }
          });
        }
      }
      // 全年票
      else if (this.productObj.allYearVotes>0){
        if (this.allYearPriceCalendar.length > 0) {
          this.allYearPriceCalendar.map(proItem => {
            if (currentMon.toString() === proItem.travelTime.split("-")[1]) {
              if (!this.includeObj(this.priceType, proItem)) {
                this.priceType.push({
                  salePrice: proItem.salePrice,
                  color: proItem.ticketColor,
                  productId: proItem.productDto.id
                });
              }
            }
          });
        }
      }
    },
    // 初始化日历选择时间
    travelDateInit() {
      if (this.productObj) {
        var ticketKind = this.productObj.value; // 票类型
      }
      if ((ticketKind === 1 || ticketKind === 9) && this.oneDayTicketAvailableDate.length > 0) {
        this.travelDate = this.oneDayTicketAvailableDate[0];
      } else if (
        [2, 5].includes(ticketKind) &&
        this.twoDayAvailableDate.length > 0
      ) {
        this.travelDate = this.twoDayAvailableDate[0];
      }
    },
    // 判断objArr是否含有与obj.salePrice的值相同的对象
    includeObj(objArr, obj) {
      let flag = false;
      for (let i = 0; i < objArr.length; i++) {
        if (
          objArr[i].salePrice === obj.salePrice &&
          objArr[i].productId === obj.productDto.id
        ) {
          flag = true;
        }
      }
      return flag;
    },
    // 获取日历数据
    getCalendarData(params) {
      return new Promise((resolve, reject) => {
        getCalendarProductList(params).then(res => {
          if (res.data.data && res.data.data.length > 0) {
            res.data.data.map(item => {
              if (item.travelTime !== undefined) {
                this.onTimeZone = item.productDto.onTimeZone;
                item.travelTime = dateTimeFormatByZone(
                  item.travelTime,
                  this.zone,
                  "YYYY-MM-DD"
                );
                const maxNum = item.productDto.maxNum; // 最大预订数
                const num = item.num; // 库存
                if (maxNum === 0) {
                  item.num = num;
                } else if (maxNum > 0) {
                  if (maxNum >= num) {
                    item.num = num;
                  } else if (num >= maxNum) {
                    item.num = maxNum;
                  }
                }
              }
            });
            this.loading = false;
            resolve(res.data.data);
          } else {
            // this.getCalendarProductList()
            this.loading = false;
            resolve([]);
          }
        });
      });
    },
    // 加入购物车
    addToCart() {
      // // this.$gtag.event('add-to-cart')
  
      if (!this.travelDate) {
        return this.$message({
          message: "Please select a play date",
          type: "warning"
        });
      }
      // 把产品记录到vuex的购物车
      var orderInfo = this.setProData();
      orderInfo.currency = 5
      // // google统计-分销商访问商品事件埋点
      this.$gtag.event("add-to-cart-Studio-Pass", {
      event_category: "studioPassAddToCart",
      event_label: "homeAddToCart",
      event_orderinfo:JSON.stringify(orderInfo),
      value: 1000
      });
      localStorage.setItem("orderInfo", JSON.stringify(orderInfo)); // 保存到本地
      this.SET_SHOPCART();
      this.SET_TICKET_DATE();
      this.$message({
        message: "Add to cart successfully",
        type: "success",
        duration: 2000
      });
      // 记录当前的时间-三小时后清除购物车-10800000 结束
      // localStorage.setItem('startAddCartDate',+new Date()) 
      localStorage.setItem('endAddCartDate',+new Date()+10800000) 

      this.dialogVisible = false;
      this.$emit("handleTicket", 1); // 侧边栏更新数据
    },
    // 数据处理
    setProData() {
      // 成人票
      if (JSON.stringify(this.adultProduct) !== "{}") {
        var adultTicket = {
          id: this.adultProduct.id,
          travelDate: this.travelDate,
          num: this.productObj.adultVotes, // 所买票数
          ticketType: this.adultProduct.ticketType,
          ticketKind: this.productObj.value,
          ticketName: this.productObj.name,
          price: this.adultProduct.salePrice,
          productId: this.adultProduct.productDto.id
        };
      } else {
        if(this.productObj.value !==7){
          var adultTicket = {
          id: 0,
          travelDate: this.travelDate,
          num: 0, // 所买票数
          ticketType: 1,
          ticketKind: 1,
          ticketName: null,
          price: 0,
          productId: 0
        };
        }
      }
      // 儿童票
      if (JSON.stringify(this.childProduct) !== "{}") {
        var childTicket = {
          id: this.childProduct.id,
          travelDate: this.travelDate,
          num: this.productObj.childVotes, // 所买票数
          ticketType: this.childProduct.ticketType,
          ticketKind: this.productObj.value,
          ticketName: this.productObj.name,
          price: this.childProduct.salePrice,
          productId: this.childProduct.productDto.id
        };
      }
      // 老人票
      if (JSON.stringify(this.seniorProduct) !== "{}") {
        var seniorTicket = {
          id: this.seniorProduct.id,
          travelDate: this.travelDate,
          num: this.productObj.seniorVotes, // 所买票数
          ticketType: this.seniorProduct.ticketType,
          ticketKind: this.productObj.value,
          ticketName: this.productObj.name,
          price: this.seniorProduct.salePrice,
          productId: this.seniorProduct.productDto.id
        };
      }
      // 全年票
      if(this.productObj.value ===7){
        if (JSON.stringify(this.allYearProduct) !== "{}") {
        var allYearTicket = {
          id: this.allYearProduct.id,
          travelDate: this.travelDate,
          num: this.productObj.allYearVotes, // 所买票数
          // ticketType: this.allYearProduct.ticketType,
          ticketKind: this.productObj.value,
          ticketName: this.productObj.name,
          price: this.allYearProduct.salePrice,
          productId: this.allYearProduct.productDto.id
        };
      }
      }
      var orderInfo = JSON.parse(localStorage.getItem("orderInfo")); // 购物车:存储了所有已选出产品信息
      // 如果本地已经存储过产品就添加
      if (orderInfo) {
        if (adultTicket) {
          orderInfo.orderRequestProductList.push(adultTicket);
        }
        if (childTicket) {
          orderInfo.orderRequestProductList.push(childTicket);
        }
        if (seniorTicket) {
          orderInfo.orderRequestProductList.push(seniorTicket);
        }
        // 全年票
        if(allYearTicket){
           orderInfo.orderRequestProductList.push(allYearTicket);
        }
      } else {
        // 否则新建并存储到本地
        var orderInfo = {};
        orderInfo.orderRequestProductList = [];
        if (adultTicket) {
          orderInfo.orderRequestProductList.push(adultTicket);
        }
        if (childTicket) {
          orderInfo.orderRequestProductList.push(childTicket);
        }
        if (seniorTicket) {
          orderInfo.orderRequestProductList.push(seniorTicket);
        }
        // 全年票
        if(allYearTicket){
           orderInfo.orderRequestProductList.push(allYearTicket);
        }
        if (JSON.stringify(this.childProduct) !== "{}") {
          orderInfo.currency = 5;
          // orderInfo.currency = this.childProduct.currency
        } else if (JSON.stringify(this.adultProduct) !== "{}") {
          orderInfo.currency = 5;
          // orderInfo.currency = this.adultProduct.currency
        } else if (JSON.stringify(this.seniorProduct) !== "{}") {
          orderInfo.currency = 5;
          // orderInfo.currency = this.adultProduct.currency
        } else if (JSON.stringify(this.allYearProduct) !== "{}") {
          // 全年票
          orderInfo.currency = 5;
          // orderInfo.currency = this.adultProduct.currency
        }
      }
      return orderInfo;
    },
    // 判断日期是否在可选日期内,不在则返回true
    notOnSelectableDate(date) {
      let notOn = false;
      if (this.productObj) {
        var ticketKind = this.productObj.value; // 票类型
      }
      // 如果是选一日票的时间且有了时间限制
      if ((ticketKind === 1 || ticketKind === 9) && this.oneDayTicketAvailableDate.length > 0) {
        this.oneDayTicketAvailableDate.includes(date)
          ? (notOn = false)
          : (notOn = true);
      } else if (
        [2, 5, 7].includes(ticketKind) &&
        this.twoDayAvailableDate.length > 0
      ) {
        // 如果是选1.5/2日票且有时间限制
        this.twoDayAvailableDate.includes(date)
          ? (notOn = false)
          : (notOn = true);
      }
      return notOn;
    },
    // 点击日期
    selectDate(date) {
      this.travelDate = date.day;
      this.getProduct();
      this.conversionPeriod(this.travelDate);
      this.judgeTicket(this.productObj.name)
    },
    // 获取所选日期的产品信息
    getProduct() {
      let switch1 = true,
        switch2 = true;
      if (this.productObj.adultVotes > 0) {
        // 如果选了成人票则去成人产品日历列表获取对应日期的产品
        if (this.adultPriceCalendar.length > 0) {
          // 如果成人产品日历列表不为空则进行查找产品
          const index = this.adultPriceCalendar.findIndex(item => {
            return item.travelTime === this.travelDate;
          });
          if (index == -1) {
            // 如果找不到对应日期的产品停止寻找
            switch1 = false;
            return this.$warning("Insufficient inventory of adult tickets");
          } else {
            for (let i = 0; i < this.adultPriceCalendar.length; i++) {
              if (this.travelDate === this.adultPriceCalendar[i].travelTime) {
                if (
                  this.productObj.adultVotes > this.adultPriceCalendar[i].num
                ) {
                  this.$warning("Insufficient inventory of adult tickets");
                  switch1 = false;
                  break;
                } else {
                  this.adultProduct = this.adultPriceCalendar[i];
                  switch1 = true;
                }
              }
            }
          }
        } else {
          this.$warning("Insufficient inventory of adult tickets");
          switch1 = false;
        }
      } else {
        if (this.adultPriceCalendar.length > 0) {
          // 如果儿童产品日历列表不为空则进行查找产品
          const index = this.adultPriceCalendar.findIndex(item => {
            return item.travelTime === this.travelDate;
          });
          if (index === -1) {
            // 如果找不到对应日期的产品停止寻找
            // switch1 = false
            // return this.$warning('Insufficient inventory of child tickets')
          } else {
            for (let i = 0; i < this.adultPriceCalendar.length; i++) {
              if (this.travelDate === this.adultPriceCalendar[i].travelTime) {
                this.adultProduct = this.adultPriceCalendar[i];
                switch1 = true;
              }
            }
          }
        }
      }
      if (this.productObj.childVotes > 0) {
        // 如果选了儿童票则去获取对应日期的产品
        if (this.childPriceCalendar.length > 0) {
          // 如果儿童产品日历列表不为空则进行查找产品
          const index = this.childPriceCalendar.findIndex(item => {
            return item.travelTime === this.travelDate;
          });
          if (index === -1) {
            // 如果找不到对应日期的产品停止寻找
            switch1 = false;
            return this.$warning("Insufficient inventory of child tickets");
          } else {
            for (let i = 0; i < this.childPriceCalendar.length; i++) {
              if (this.travelDate === this.childPriceCalendar[i].travelTime) {
                if (
                  this.productObj.childVotes > this.childPriceCalendar[i].num
                ) {
                  switch1 = false;
                  this.$warning("Insufficient inventory of child tickets");
                  break;
                } else {
                  this.childProduct = this.childPriceCalendar[i];
                  switch1 = true;
                }
              }
            }
          }
        } else {
          this.$warning("Insufficient inventory of child tickets");
          switch2 = false;
        }
      } else {
        if (this.childPriceCalendar.length > 0) {
          // 如果儿童产品日历列表不为空则进行查找产品
          const index = this.childPriceCalendar.findIndex(item => {
            return item.travelTime === this.travelDate;
          });
          if (index === -1) {
            // 如果找不到对应日期的产品停止寻找
            // switch1 = false
            // return this.$warning('Insufficient inventory of child tickets')
          } else {
            for (let i = 0; i < this.childPriceCalendar.length; i++) {
              if (this.travelDate === this.childPriceCalendar[i].travelTime) {
                this.childProduct = this.childPriceCalendar[i];
                switch1 = true;
              }
            }
          }
        }
      }
      if (this.productObj.seniorVotes > 0) {
        // 如果选了老人票则去获取对应日期的产品
        if (this.seniorPriceCalendar.length > 0) {
          // 如果老人产品日历列表不为空则进行查找产品
          const index = this.seniorPriceCalendar.findIndex(item => {
            return item.travelTime === this.travelDate;
          });
          if (index === -1) {
            // 如果找不到对应日期的产品停止寻找
            switch1 = false;
            return this.$warning("Insufficient inventory of senior tickets");
          } else {
            for (let i = 0; i < this.seniorPriceCalendar.length; i++) {
              if (this.travelDate === this.seniorPriceCalendar[i].travelTime) {
                if (
                  this.productObj.seniorVotes > this.seniorPriceCalendar[i].num
                ) {
                  switch1 = false;
                  this.$warning("Insufficient inventory of senior tickets");
                  break;
                } else {
                  this.seniorProduct = this.seniorPriceCalendar[i];
                  switch1 = true;
                }
              }
            }
          }
        } else {
          this.$warning("Insufficient inventory of senior tickets");
          switch2 = false;
        }
      } else {
        if (this.seniorPriceCalendar.length > 0) {
          // 如果老人产品日历列表不为空则进行查找产品
          const index = this.seniorPriceCalendar.findIndex(item => {
            return item.travelTime === this.travelDate;
          });
          if (index === -1) {
            // 如果找不到对应日期的产品停止寻找
            // switch1 = false
            // return this.$warning('Insufficient inventory of child tickets')
          } else {
            for (let i = 0; i < this.seniorPriceCalendar.length; i++) {
              if (this.travelDate === this.seniorPriceCalendar[i].travelTime) {
                this.seniorProduct = this.seniorPriceCalendar[i];
                switch1 = true;
              }
            }
          }
        }
      }
        if (this.productObj.allYearVotes > 0) {
        // 如果选了全年票则去获取对应日期的产品
        if (this.allYearPriceCalendar.length > 0) {
          // 如果全年产品日历列表不为空则进行查找产品
          const index = this.allYearPriceCalendar.findIndex(item => {
            return item.travelTime === this.travelDate;
          });
          if (index === -1) {
            // 如果找不到对应日期的产品停止寻找
            switch1 = false;
            return this.$warning("Insufficient inventory of senior tickets");
          } else {
            for (let i = 0; i < this.allYearPriceCalendar.length; i++) {
              if (this.travelDate === this.allYearPriceCalendar[i].travelTime) {
                if (
                  this.productObj.seniorVotes > this.allYearPriceCalendar[i].num
                ) {
                  switch1 = false;
                  this.$warning("Insufficient inventory of senior tickets");
                  break;
                } else {
                  this.allYearProduct = this.allYearPriceCalendar[i];
                  switch1 = true;
                }
              }
            }
          }
        } else {
          this.$warning("Insufficient inventory of senior tickets");
          switch2 = false;
        }
      } else {
        if (this.allYearPriceCalendar.length > 0) {
          // 如果全年产品日历列表不为空则进行查找产品
          const index = this.allYearPriceCalendar.findIndex(item => {
            return item.travelTime === this.travelDate;
          });
          if (index === -1) {
            // 如果找不到对应日期的产品停止寻找
            // switch1 = false
            // return this.$warning('Insufficient inventory of child tickets')
          } else {
            for (let i = 0; i < this.allYearPriceCalendar.length; i++) {
              if (this.travelDate === this.allYearPriceCalendar[i].travelTime) {
                this.seniorProduct = this.allYearPriceCalendar[i];
                switch1 = true;
              }
            }
          }
        }
      }
      this.btnDisabled = !(switch1 && switch2);
    },
    // 总价钱
    subtotal() {
      if (this.productObj.adultVotes > 0 && this.productObj.childVotes > 0 && this.productObj.seniorVotes > 0) {
        return (
          this.adultProduct.salePrice * this.productObj.adultVotes +
          this.childProduct.salePrice * this.productObj.childVotes +
          this.seniorProduct.salePrice * this.productObj.seniorVotes
        );
      } else if (this.productObj.adultVotes > 0 && this.productObj.childVotes > 0) {
        return (
          this.adultProduct.salePrice * this.productObj.adultVotes +
          this.childProduct.salePrice * this.productObj.childVotes
        );
      } else if (this.productObj.adultVotes > 0 && this.productObj.seniorVotes > 0) {
        return (
          this.adultProduct.salePrice * this.productObj.adultVotes +
          this.seniorProduct.salePrice * this.productObj.seniorVotes
        );
      } else if (this.productObj.childVotes > 0 && this.productObj.seniorVotes > 0) {
        return (
          this.childProduct.salePrice * this.productObj.childVotes +
          this.seniorProduct.salePrice * this.productObj.seniorVotes
        );
      } else if (this.productObj.adultVotes) {
        return this.adultProduct.salePrice * this.productObj.adultVotes;
      } else if (this.productObj.childVotes) {
        return this.childProduct.salePrice * this.productObj.childVotes;
      } else if (this.productObj.seniorVotes) {
        return this.seniorProduct.salePrice * this.productObj.seniorVotes;
      } else if (this.productObj.allYearVotes>0){
        // 全年票
        return this.allYearProduct.salePrice * this.productObj.allYearVotes;
      }
    },
    // 设置有限期范围
    conversionPeriod() {
      var startTime = this.travelDate;
      var endTime = "";
      endTime = getDateStr(startTime, 90);
      startTime = formatDate(startTime);
      endTime = formatDate(endTime);
      return `Valid any same price day from ${startTime} ~ ${endTime}`;
    },
    // 默认获取今天的票价
    getSalePrice() {
      const date = new Date();
      const month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const today = `${date.getFullYear()}-${month}-${day}`;
      for (let i = 0; i < this.adultPriceCalendar.length; i++) {
        if (this.adultPriceCalendar[i].travelTime === today) {
          this.adultProduct = this.adultPriceCalendar[i];
          break;
        }
      }
      for (let i = 0; i < this.childPriceCalendar.length; i++) {
        if (this.childPriceCalendar[i].travelTime === today) {
          this.childProduct = this.childPriceCalendar[i];
          break;
        }
      }
      // 全年票
      for (let i = 0; i < this.allYearPriceCalendar.length; i++) {
        if (this.allYearPriceCalendar[i].travelTime === today) {
          this.allYearProduct = this.allYearPriceCalendar[i];
          break;
        }
      }
    },
    // 获取日历当天的价格和颜色
    getProInfo(date, tikcetType) {
      var pros = [];
      var returnPro = {};
      if (
        tikcetType == "adultPro" &&
        this.adultPriceCalendar.length > 0 &&
        this.productObj.adultVotes > 0
      ) {
        for (let i = 0; i < this.adultPriceCalendar.length; i++) {
          if (this.adultPriceCalendar[i].travelTime == date) {
            pros.push(this.adultPriceCalendar[i]);
          }
        }
        returnPro = pros.pop();
      } else if (
        tikcetType == "childPro" &&
        this.childPriceCalendar.length > 0 &&
        this.productObj.childVotes > 0
      ) {
        for (let i = 0; i < this.childPriceCalendar.length; i++) {
          if (this.childPriceCalendar[i].travelTime == date) {
            pros.push(this.childPriceCalendar[i]);
          }
        }
        returnPro = pros.pop();
      }
      else if (
        tikcetType == "seniorPro" &&
        this.seniorPriceCalendar.length > 0 &&
        this.productObj.seniorVotes > 0
      ) {
        for (let i = 0; i < this.seniorPriceCalendar.length; i++) {
          if (this.seniorPriceCalendar[i].travelTime == date) {
            pros.push(this.seniorPriceCalendar[i]);

          }
        }
        returnPro = pros.pop();
      }
      // 全年票
      else if (
        tikcetType == "allYearPro" &&
        this.allYearPriceCalendar.length > 0 &&
        this.productObj.allYearVotes > 0
      ) {
        for (let i = 0; i < this.allYearPriceCalendar.length; i++) {
          if (this.allYearPriceCalendar[i].travelTime == date) {
            pros.push(this.allYearPriceCalendar[i]);
          }
        }
        returnPro = pros.pop();
      }
      // console.log(date, tikcetType,returnPro,this.adultPriceCalendar);
      return returnPro;
    },
    // 判断是否显示 No stock
    ifNoStock(day){
      let val = false
      // let newArr = [
      //   '2023-10-24',
      //   '2023-10-25',
      //   '2023-10-26',
      //   '2023-10-27',
      //   '2023-10-28',
      //   '2023-10-29',
      //   '2023-10-30',
      //   '2023-10-31',
      //   '2023-11-01',
      //   '2023-11-02',
      //   '2023-11-03',
      //   '2023-11-04',
      //   '2023-11-05'
      // ]
      let newArr = this.ifNoStockList;
      newArr.forEach(item => {
        if(item == day){
          val = true
        }
      })
      // console.log('ifNoStock',day,val);
      return val;
    },
    // 库存不足返回true
    InventoryShortage(day) {
      let switch1 = false,
        switch2 = false,
        switch3 = false,
        switch4 = false;
      if (this.productObj.adultVotes > 0) {
        // 如果选择了成人票则看看是否存在当天的产品
        if (this.adultPriceCalendar.length > 0) {
          const index = this.adultPriceCalendar.findIndex(item => {
            return item.travelTime === day;
          });
          if (index === -1) {
            // console.log('进来了',day);
            switch1 = true;
          } else {
            this.adultPriceCalendar.map(item => {
              if (
                item.travelTime === day &&
                this.productObj.adultVotes > item.num
              ) {
                // 如果大于库存，库存不足
                switch1 = true;
              }
            });
          }
        } else {
          switch1 = true;
        }
      } else {
        switch1 = false;
      }
      if (this.productObj.childVotes > 0) {
        // 如果选择了儿童则判断是否有当天的产品
        if (this.childPriceCalendar.length > 0) {
          const index = this.childPriceCalendar.findIndex(item => {
            return item.travelTime === day;
          });
          // 如果不存在该日期的产品
          if (index === -1) {
            switch2 = true;
          } else {
            this.childPriceCalendar.map(item => {
              if (
                item.travelTime === day &&
                this.productObj.childVotes > item.num
              ) {
                // 如果大于库存，库存不足
                switch2 = true;
              }
            });
          }
        } else {
          switch2 = true;
        }
      } else {
        switch2 = false;
      }
      if (this.productObj.seniorVotes > 0) {
        // 如果选择了老人则判断是否有当天的产品
        if (this.seniorPriceCalendar.length > 0) {
          const index = this.seniorPriceCalendar.findIndex(item => {
            return item.travelTime === day;
          });
          // 如果不存在该日期的产品
          if (index === -1) {
            switch3 = true;
          } else {
            this.seniorPriceCalendar.map(item => {
              if (
                item.travelTime === day &&
                this.productObj.seniorVotes > item.num
              ) {
                // 如果大于库存，库存不足
                switch3 = true;
              }
            });
          }
        } else {
          switch3 = true;
        }
      } else {
        switch3 = false;
      }
      // todo 后续隐藏跨年票
      // if (this.productObj.allYearVotes > 0) {
      //   // 如果选择了全年则判断是否有当天的产品
      //   if (this.allYearPriceCalendar.length > 0) {
      //     const index = this.allYearPriceCalendar.findIndex(item => {
      //       return item.travelTime === day;
      //     });
      //     // 如果不存在该日期的产品
      //     if (index === -1) {
      //       switch4 = true;
      //     } else {
      //       this.allYearPriceCalendar.map(item => {
      //         if (
      //           item.travelTime === day &&
      //           this.productObj.allYearVotes > item.num
      //         ) {
      //           // 如果大于库存，库存不足
      //           switch4 = true;
      //         }
      //       });
      //     }
      //   } else {
      //     switch4 = true;
      //   }
      // } else {
      //   switch4 = false;
      // }
      // todo 后续隐藏跨年票------分割线
      return switch1 || switch2 || switch3 || switch4;
    },
    // 判断日期是否在六十天内
    betweenDay(day) {
      const startTime = new Date(this.getNowFormatDate()).getTime();
      const endTime = new Date(getDateStr(startTime, this.differDay)).getTime();
      const curTime = new Date(day).getTime();
      return curTime < startTime || curTime > endTime ? false : true;
    },
    // 获取总价
    getTotal() {
      let price1 = 0;
      let price2 = 0;
      if (this.productObj.adultVotes > 0 && this.adultProduct) {
        price1 = this.productObj.adultVotes * this.adultProduct.salePrice;
      }
      if (this.productObj.childVotes > 0 && this.childProduct) {
        price2 = this.productObj.childVotes * this.childProduct.salePrice;
      }
      return price1 + price2;
    },
    handleDetail() {
      this.dialogVisible = false;
      this.$emit("openDetail", this.productObj);
    },
    // 上一月
    async prevMonth() {
      let prevMon = this.date.toString().indexOf('GMT-') === -1 ? this.date.getMonth() : this.date.getMonth() + 1
      // judgeMonthFrist(prevMon - 1, 0)
      prevMon < 10 ? prevMon = '0' + prevMon : ''
      if (prevMon.toString().substr(0, 1) !== "0") {
        prevMon < 10 && this.isMac ? prevMon = '0' + prevMon : ''
      }
      let prevYear = this.date.getFullYear()
      if (prevMon - 1 < 0) {
        prevMon = 12
        prevYear = prevYear - 1
      }
      this.date = new Date(`${prevYear}-${prevMon}-01`)
      this.loading = true;
      this.isShowBefore = false;
      setTimeout(() => {
        this.loading = false;
        this.isShowBefore = true;
      }, 200);
      await this.getCalendarProductList();
      this.getPriceType();
      this.$forceUpdate();
    },
    // 下一月
    async nextMonth() {
      let nextMon = this.date.toString().indexOf('GMT-') === -1 ? this.date.getMonth() + 2 : this.date.getMonth() + 2
      // judgeMonthFrist(nextMon - 1, 12)
      nextMon < 10 ? nextMon = '0' + nextMon : ''
      if (nextMon.toString().substr(0, 1) !== "0") {
        nextMon < 10 && this.isMac ? nextMon = '0' + nextMon : ''
      }
      let year = this.date.getFullYear()
      if (parseInt(nextMon) > 12) {
        nextMon = '01'
        year = year + 1
      }
      // nextMon < 10 ? this.date = new Date(`${year}-0${nextMon + 1}-01`) : this.date = new Date(`${year}-${nextMon + 1}-01`)
      // 问题所在
      this.date = new Date(`${year}/${nextMon}/01`)
      console.log('票列表',`${year}-${nextMon}-01`,this.date);
      await this.getCalendarProductList();

      this.getPriceType();
      this.$forceUpdate();
    },

    // 获取一日两日票的英文名
    getTicketTypeName(value) {
      const options = {
        1: "1",
        2: "2",
        5: "1.5",
        9: "1"
      };
      return options[value];
    },
    // 获取时区列表
    async getTimezones() {
      await axios
        .get("https://nta.tripodeck.com/api/ops/app/timezones")
        .then(res => {
          if (res.data.code === 0) {
            this.timeZoneList = res.data.data;
          } else {
            this.timeZoneList = [];
          }
        });
      this.getTimeZone();
    },
    // 获取单个时区的zone
    getTimeZone() {
      if (this.timeZoneList.length > 0) {
        this.timeZoneList.map(item => {
          if (item.value === this.onTimeZone) {
            this.zone = item.zone;
          }
        });
      }
    },
    // 关闭弹框
    closeDialog() {
      this.date = new Date()
      document.documentElement.scrollTop = this.$store.state.scrollTop;
      this.dialogVisible = false;
    },
    // 获取当天的年月日
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    // 月份转换为英文格式
    dateFilter(date) {
      var month = date.getMonth();
      var year = date.getFullYear();
      var options = {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December"
      };
      if (month < 12) {
        return options[month + 1] + " " + year;
      } else {
        return options[1] + " " + (year + 1);
      }
    },
    uniquePrice(obj) {
      var prices = [];
      prices.push(obj);
      return prices[prices.length - 1];
    }
  }
};
</script>
<style lang="scss" scoped>
.dialog-body {
  padding-left: 22px;
  // height: 60vh;
  overflow: auto;
}
.big_title {
  padding-left: 10px;
  margin-bottom: 8px;
  margin-top: 10px;
  color: #333;
}
.dialog-foot {
  border-radius: 0 0 12px 12px;
  text-align: right;
  background-color: #eeeeee;
  padding: 8px 32px 16px;
  border-top: 6px solid rgb(3, 108, 226);
  .subtotal {
    margin-bottom: 8px;
    font-size: 16px;
  }
  .el-button {
    border-radius: 13px;
  }
  .el-button--primary.is-plain {
    background-color: white;
  }
  .el-button--primary {
    background-color: #036ce2;
    color: #036ce2;
    margin-right: 16px;
    border: 1px solid #036ce2;
    font-size: 14px;
    font-weight: 500;
    border-radius: 12px;
  }
}
.priceLevelBox {
  // visibility: hidden;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  padding-left: 7px;
  .priceColorBox {
    .price-level-item {
      display: -webkit-box;
      display: -moz-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      margin-right: 12px;
      .cube {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
      .price {
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
}
.dateCell {
  min-height: 55.99px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 2px 4px;
  // 日历内边框样式
  // border: 1px solid #979797;
  .color-bar {
    width: 100%;
    height: 5px;
  }
}
::v-deep .el-calendar-table td {
  border: 1px solid black;
  background-color: #eeeeee;
}
::v-deep .el-calendar-table td .el-calendar-day {
  /*日历格不可点击样式 */
  .datecell_disclick {
    background-color: rgb(238, 238, 238);
    color: #333333;
    & {
      pointer-events: none;
    }
  }
  // 点击后的样式
  .click_active {
    background-color: rgb(22, 43, 116) !important;
    color: white !important;
  }
  /*日历格可点击样式*/
  .datecell_click {
    background-color: white;
    color: #333;
    &:hover {
      background-color: rgb(22, 43, 116);
      color: white;
    }
  }
}
.priceDetail {
  text-align: right;
  padding-right: 20px;
  color: #333333;
  display: flex;
  flex-direction: column;
  p {
    font-size: 14px;
    font-weight: 400;
    span:last-child {
      font-size: 16px;
      font-weight: 700;
    }
  }
}
.addCartBtn {
  background-color: #52a41d;
  border-radius: 13px;
}
.select_product_info {
  word-break: normal;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: rgb(19, 66, 140);
  margin-top: 4px;
  i {
    color: white;
  }
  img {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
}
::v-deep .el-calendar__header {
  border-bottom: 0;
  padding: 0;
}

::v-deep .el-dialog {
  max-width: 900px;
  width: 100%;
  // height: 100%;
  border-radius: 12px;
  // margin-top: 15vh !important;
}
::v-deep .el-dialog__header {
  display: none;
}
::v-deep .el-dialog__body {
  padding: 20px 0 0 0;
  font-size: 12px;
}
::v-deep .dialog-body-right {
  border-left: 1px solid gainsboro;
  .gwcts{
    padding: 8px;
    border: 2px solid red;
    color: red;
    margin-right: 20px;
    margin-bottom: 12px;
    background-color:#fbf1f0;
    border-radius: 6px;
  }
  .currentDate {
    font-size: 18px;
    line-height: 20px;
    color: rgb(22, 43, 117);
    font-weight: 700;
    text-align: center;
    .calendar_arrow {
      cursor: pointer;
      width: 15px;
      height: 15px;
    }
    i {
      // vertical-align: text-top;
      font-weight: 600;
    }
  }
}
::v-deep .el-calendar__body {
  padding-bottom: 15px;
  padding-left: 7.5px;
  padding-right: 20px;
  .tbody {
    border-color: red;
  }
}
::v-deep .el-calendar-table thead th {
  font-weight: bold;
  color: #333333;
}
::v-deep .el-calendar__title {
  display: none;
}
::v-deep .el-calendar__button-group {
  display: none;
}
::v-deep .el-calendar-table .el-calendar-day {
  // min-width: 80px;
  height: auto;
  padding: 0;
}
::v-deep .el-button--success.is-disabled,
.el-button--success.is-disabled:active,
.el-button--success.is-disabled:focus,
.el-button--success.is-disabled:hover {
  background-color: rgba(82, 164, 29, 0.5);
  font-weight: 700;
}
::v-deep .el-calendar-table td.is-selected {
  background-color: rgb(22, 43, 117) !important;
  color: white !important;
}
::v-deep .el-calendar-table td.is-today {
  color: inherit;
  background-color: inherit;
}
::v-deep .el-calendar-table .el-calendar-day:hover {
  background-color: rgb(22, 43, 117);
  color: white;
}
// 设置日历第一个日历格的左边框
::v-deep .el-calendar-table tr td:first-child {
  // border-left: 1px solid rgb(151, 151, 151);
}
::v-deep .el-calendar-table tr:first-child td {
  border-top: 1px solid rgb(151, 151, 151);
}
.calendar-head {
  vertical-align: text-top;
}
@media (max-width: 992px) {
  .dialog-body-right {
    border-left: 0;
  }

  ::v-deep .el-dialog {
    margin-top: 0 !important;
    margin-bottom: 0;
    height: 100vh;
    border-radius: 0;
    .el-dialog__body {
      // height: 100vh;
      .dialog-body {
        height: calc(100vh - 180px);
        padding-left: 5px;
      }
    }
  }
  .dialog-foot {
    padding: 16px;
    border-radius: 0;
    .subtotal {
      display: flex;
      justify-content: space-between;
      span:first-child {
        font-size: 20px;
        line-height: 1.5;
        font-weight: 500;
      }
    }
    .btn_box {
      display: flex;
      flex-direction: column-reverse;

      .cancel_btn {
        background-color: white;
        margin-top: 10px;
      }
      .el-button {
        display: block;
        width: 100%;
        border: none;
      }
    }
  }
  .dialog-foot {
    .el-button--primary {
      font-weight: 400;
      color: #036ce2;
    }
  }
  .priceDetail {
    p {
      white-space: nowrap;
    }
  }
  .select_product_info {
    padding-left: 0;
  }
  ::v-deep .el-calendar__body {
    padding-right: 15px;
    padding-left: 0;
  }
}
.padding_b_16 {
  padding-bottom: 16px;
}
@media (min-width: 768px) {
  ::v-deep .el-calendar-table .el-calendar-day {
    // height: 46.99px;
    padding-top: 0;
  }
}
// 设置日历箭头hover样式为手指
.el-icon-arrow-left,
.el-icon-arrow-right {
  font-size: 24px;
  cursor: pointer;
}
::v-deep .el-calendar-table {
  &:not(.is-range) {
    td.current,
    td.is-selected {
      border: 1px solid black;
    }
    td {
      border: 1px solid black;
    }
    td.next {
      border: none;
      display: none;
    }
    td.prev {
      visibility: hidden;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #333333;
    }
  }
}
// 时间为八月份时隐藏日历第一行，临时解决八月份时第一行没有顶上的问题
.hiddenPre {
  .el-calendar-table:not(.is-range) td.prev {
    display: none;
  }
  .el-calendar-table:not(.is-range) td.next {
    /*隐藏下个月的日期*/
    display: none;
  }
}
.el-calendar-table:not(.is-range) td.next {
  /*隐藏下个月的日期*/
  visibility: hidden;
}
</style>
